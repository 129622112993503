import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Section from '../components/Section';
import LinkWrapper from '../components/LinkWrapper';
import LegalTextblock from '../components/LegalTextblock';
import { colors } from '../helpers/variables';

/** Angepasster Link */
const StyledLink = styled(LinkWrapper)`
    color: ${colors.primary};
`;

const ImprintPage = () => (
    <Layout title="Impressum">
        <Section bg="gray" container="l">
            <LegalTextblock>
                <div>
                    <h1>Impressum</h1>
                    <h2>Herausgeber & inhaltlich verantwortlich</h2>
                    <strong>Gottwald Elektrotechnik</strong>
                    <br />
                    Adolf Gottwald Elektrotechnik GbR
                    <br />
                    Durlanger Str. 24
                    <br />
                    73568 Durlangen- Zimmerbach
                </div>
                <div>
                    <h2>Kontakt</h2>
                    <strong>Gottwald Elektrotechnik</strong>
                    <br />
                    Durlanger Str. 24
                    <br />
                    73568 Durlangen- Zimmerbach
                    <br />
                    {/* eslint-disable-next-line */}
                    Tel: <StyledLink to="tel:0 71 76 / 23 98">0 71 76 / 23 98</StyledLink>
                    <br />
                    Fax: 0 71 76 / 36 56
                    <br />
                    <StyledLink to="mailto:info@gottwald-elektrotechnik.de">
                        info@gottwald-elektrotechnik.de
                    </StyledLink>
                </div>
                <div>
                    <h2>Sitz der Gesellschaft</h2>
                    Durlangen- Zimmerbach
                </div>
                <div>
                    <h2>Umsatzsteuer-ID-Nr. gem. §27a UStG</h2>
                    DE146689950
                </div>
            </LegalTextblock>
            <LegalTextblock>
                <h2>Gestaltung</h2>
                <StyledLink to="http://www.jochenstrotzer.de" target="_blank">
                    Jochen Strotzer - visuelle Kommunikation
                </StyledLink>
            </LegalTextblock>
        </Section>
    </Layout>
);

export default ImprintPage;
